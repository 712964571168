import { useStaticQuery, graphql } from 'gatsby';

export const useFeatured = () => {
    const HomeFeatured = useStaticQuery(
        graphql`
            query GET_HOME_FEATURED_CONTENT {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    acf {
                        featured_events_classes {
                            event_links_learn
                            event_images_learn {
 localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                            }
                           
                        }
                       
                            }
                        }
                    }
        `
    );
    return HomeFeatured;
};
