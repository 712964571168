import React, { Component } from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class Blog extends Component {
    render() {
        const { blogList } = this.props;
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return (
            <section className="page-section bg-primary text-white blogslider display_none">
                <h2 className="yellowtxt">Read Our Blog</h2>
                <div
                    id="myCarousel8"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider {...settings} className="carousel-inner blog_home">
                        {blogList.map(post => (
                            <div className="item carousel-item" key={post.id}>
                                <div className="featslider">
                                    <Img
                                        className="whiteborderimg blog_home_image"
                                        fluid={
                                            post.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                    />
                                    <div className="dateslider">
                                        <span className="bslday">
                                            {new Date(post.date).getDay()}
                                        </span>
                                        <span className="bslmonth">
                                            {
                                                months[
                                                    new Date(
                                                        post.date
                                                    ).getMonth()
                                                ]
                                            }
                                        </span>
                                    </div>
                                </div>

                                <div className="bslcont">
                                    <h3
                                        className="bslittl"
                                        dangerouslySetInnerHTML={{
                                            __html: post.title,
                                        }}
                                    />
                                    <p
                                        className="bslexc"
                                        dangerouslySetInnerHTML={{
                                            __html: post.excerpt,
                                        }}
                                    />
                                    <Link
                                        to={`/${post.slug}`}
                                        className="wrtsbtn yellowbtn"
                                    >
                                        READ ARTICLE
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        );
    }
}
export default Blog;
