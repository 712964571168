import React, { Component } from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};
const dotsSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 22,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        const { testimonialsList } = this.props;
        return (
            <section className="page-section text-white testimsec">
                <div className='elf-boxed'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<div class="elfsight-app-b76a2c0c-ddc3-4f4a-9dc0-13ae2b153dad" data-elfsight-app-lazy></div>`,
                        }}
                    />
                </div>
                <div className="elf-boxed"
                    dangerouslySetInnerHTML={{
                        __html: `<div class="elfsight-app-aeb809ef-b0a0-4ead-94bb-ce209a8eda73" data-elfsight-app-lazy></div>`,
                    }}
                />
                <h2 className="yellowtxt display_none">What Parents Say</h2>
                <div id="myCarousel" className="carousel slide display_none">
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        {testimonialsList.what_parents_say_list.map(
                            (testimonial, i) => (
                                <li key={i}></li>
                            )
                        )}
                    </Slider>

                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                    >
                        {testimonialsList.what_parents_say_list.map(
                            (testimonial, i) => (
                                <div
                                    className="item carousel-item active"
                                    key={i}
                                >
                                    <p
                                        className="testimonial"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                testimonial.parents_testimonial,
                                        }}
                                    />
                                    <p
                                        className="overview"
                                        dangerouslySetInnerHTML={{
                                            __html: testimonial.parents_name,
                                        }}
                                    />
                                </div>
                            )
                        )}
                    </Slider>
                    <Img
                        className="quoteimg"
                        fixed={
                            testimonialsList.what_parents_say_image.localFile
                                .childImageSharp.fixed
                        }
                        alt="testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default Testimonials;
