import React, { Component } from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024, // Adjust this value based on your design
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768, // Adjust this value based on your design
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        // Add more breakpoints and settings as needed
    ],
};
const dotsSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class Featured extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        const { featuredList } = this.props;
        return (
            <section className="page-section text-white testimsec featured_flexed">
                <h2 className="bluetxt display_none">Featured Events & Classes</h2>
                <div className='elf-boxed'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<div class="elfsight-app-9e856bea-2af6-40cd-9a64-07cb901c484c" data-elfsight-app-lazy></div>`,
                        }}
                    />
                </div>
                
                <div id="myCarousel_event" className="carousel slide display_none">
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        {featuredList.featured_events_classes.map(
                            (testimonial, i) => (
                                <li key={i}></li>
                            )
                        )}
                    </Slider>

                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                    >
                        {featuredList.featured_events_classes.map(
                            (testimonial, i) => (
                                <div
                                    className="item carousel-item active"
                                    key={i}
                                >
                                    <img src={testimonial.event_images_learn.localFile.childImageSharp.fluid.src}
                                        // fluid={
                                        //     testimonial.event_images_learn.localFile
                                        //         .childImageSharp.fluid
                                        // }  
                                        alt="flyer" />
                                    <a href={testimonial.event_links_learn} className="testimonial featured_events"
                                    > Learn More</a>

                                </div>
                            )
                        )}
                    </Slider>
                </div>
            </section>
        );
    }
}

export default Featured;
